<template>
  <el-dialog width="600" title="系統API-版本資訊設定" :visible="dialogVisible" @close="dialogDataOnClose" @open="dialogDataOnOpen()">
    <div style="text-align: center">
      <el-form ref="dataForm" :rules="rules" :model="updateQuery" size="small" label-width="100px">
        <el-row :gutter="20">
          <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
            <el-form-item prop="apiVersion" label="版本號碼" >
              <el-input id="apiVersion" :disabled="isModify" size="small"  v-model="updateQuery.apiVersion" placeholder="最多3階表示,例如:2.2.1" class="filter-item"
                        clearable style="width: 95%"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
            <el-form-item  prop="url" label="API URL">
              <el-input id="url" size="small"  v-model="updateQuery.url" placeholder="例如:https://example.com.tw/ocpi/2.2.1" class="filter-item"
                        clearable style="width: 95%">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
    </div>
    <br/><br/>
    <el-button style="margin-bottom: 0px; margin-top: 10px;" size="median" type="primary"  @click="updateData()">{{$t('Save')}}</el-button>

    <br/><br/>
  </el-dialog>

</template>

<script>
import _ from "lodash";

export default {
  name: "SystemApiVersionSetting",
  props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
    apiVersionData: {
      require: false,
      default: undefined,
      type: Object
    },
    dialogVisible: {
      require: true,
      default: undefined,
      type: Boolean
    },
    isModify: {
      require: true,
      default: undefined,
      type: Boolean
    }
  },
  data() {

    let apiVersion = (rule, value, callback) =>{
      console.log(value)
      if(/^(?:(\d+))(?:\.(\d+))?(?:\.(\*|\d+))?$/.test(value)){
        callback();
      }else{
        callback(new Error(this.$t('IncorrectCode')));
      }

    };

    let url = (rule, value, callback) =>{
      console.log(value)
      if(/^(?:^|\s)((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)$/.test(value)){
        callback();
      }else{
        callback(new Error(this.$t('IncorrectCode')));
      }

    };

    return {
      data: [],   //el-transfer的資料輸入,array[object]
      value: [],  //el-transfer的被選取資料的index, array[int]
      service: {  //接收prop serviceData值
        id:undefined,
        name:undefined,
        code:undefined,
        description:undefined,
        enabledOCPI:undefined,
        ocpiPartyId: undefined,
        ocpiCountryCode: undefined,
        ocpiVersionId: undefined,
        partnerCPOs:[],
        partnerEMSPs:[],
        exclude_partnerCPOs:[],
        exclude_partnerEMSPs:[],
      },
      updateQuery: {
        id: undefined,
        apiVersion:undefined,
        url:undefined,
        updateTime:undefined

      },
      allCPOs:[], //service的所有CPOs,由partnerCPOs+exclude_partnerCPOs合成,
      rules: {
        apiVersion: [{ required: true, validator: apiVersion, trigger: 'blur' }] ,
        url: [{ required: true, validator: url, trigger: 'blur' }] ,
      },
    };
  },
  watch: {
    apiVersionData(val) {
      if (val) {
        console.log(val);
      }else{
        console.log(val);
      }
    }
  },
  methods: {
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
          if (valid) {
              // update
              this.axios.put('/api/v1/ocpi/api/system/versions', this.updateQuery).then(() => {
                  this.$message({
                      showClose: true,
                      message: this.$t('SuccessEdited'),
                      type: 'success'
                  })
                  this.dialogDataOnClose()
              }).catch(err => {
                  console.log('err:' + err)
                  this.$message({
                      message: this.$t('FailEdited') + ' ('+err.data.msg+')' ,
                      type: 'error'
                  })
              })
          } else {
              this.$message({
                  message: this.$t('IncorrectFormat'),
                  type: 'error'
              })
          }
      })
    },
    dialogDataOnClose() {
      this.resetUpdateQuery();
      this.$refs.dataForm.resetFields() // 表單校驗結果重置
      this.$emit('close') // 觸發父層 close
    },
    dialogDataOnOpen(){
      let vm = this;
      this.updateQuery.id = this.apiVersionData.id,
      this.updateQuery.apiVersion = this.apiVersionData.apiVersion,
      this.updateQuery.url = this.apiVersionData.url,
      this.updateQuery.updateTime = this.apiVersionData.updateTime
    },
    resetUpdateQuery() {
      this.updateQuery = {
        id: undefined,
        apiVersion:undefined,
        url:undefined

      }
    },
  }
}
</script>

<style>
.el-form-item__error{
  top: 80%
}
/* Medium devices (tablets, 768px and up) */
@media (max-width: 767.98px) {
  .el-form-item__content {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .el-form-item__content {
    text-align: left;
    margin-left: 120px;
  }

  .el-form-item__label {
    width: 120px;
  }
}
</style>
