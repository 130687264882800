<template>
  <div>

    <el-tabs id="tabs" v-model="activeName" type="border-card" style="z-index: 100;">
      <el-tab-pane label="系統API" name="system-api"><keep-alive><SystemAPI v-if="activeName=='system-api'"/></keep-alive></el-tab-pane>
      <el-tab-pane id="partnerAPI" label="營運商API" name="partner-api"><keep-alive><PartnerAPI v-if="activeName=='partner-api'"/></keep-alive></el-tab-pane>
    </el-tabs>


  </div>
</template>

<script>
import SystemAPI from './SytemApiManagement.vue'
import PartnerAPI from "@/views/ocpi/PartnerApiManagement.vue"



export default {
  name: "ApiManagement",
  components: {
    SystemAPI,
    PartnerAPI,
  },
  data() {
    return {
      activeName: 'system-api',
      listQuery: {
        type: 'general',
        enabled: true,
        name: undefined,
        serviceId: undefined,
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 10
      },
      apiVersionList: [],
    };
  },
  watch:{

  },
  methods: {

  },
  created() {

  }
}
</script>

<style scoped>

</style>
