<template>
  <div>
    <el-row>
      <div v-if="serviceList.length == 0" style="float: left;"><span>無OCPI營運商</span></div>
      <el-form v-else label-width="auto" style="max-width: 100px" id="selectForm">
        <el-form-item label="營運商">
            <el-select  v-model="listQuery.serviceId" size="small" v-bind:placeholder="$t('AllOperator')" class="filter-item-mid" v-if="serviceList.length>0">
              <el-option v-for="item in serviceList" :key="item.id" :label="item.name + ' (' + item.code + ')'" :value="item.id" />
            </el-select>
        </el-form-item>
      </el-form>

    </el-row>
    <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10" style="margin-bottom: 10px; padding: 7px;">
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <span class="role-span">營運商API-版本資訊</span>
<!--          <el-button style="float: right;" type="primary" size="small" @click="handleAdd">新增版本</el-button>-->
        </div>
        <el-table
            ref="apiVersionTable"
            highlight-current-row
            v-loading="listLoading"
            :data="apiVersionList" style="width: 100%;"
            :cell-style="{padding: '0', height: '40px'}"
            :row-class-name="tableRowClassName"
            @current-change="handleCurrentChange"
        >
          <el-table-column align="center" prop="apiVersion" label="版本號" sortable/>
          <el-table-column align="center" prop="url" label="API URL" min-width="120px" :show-overflow-tooltip="true"/>
          <el-table-column align="center" prop="updateTime" label="更新時間" min-width="120px" sortable/>
          <el-table-column align="center" :label="$t('Operate')" width="130px">
            <template slot-scope="scope">
<!--              <el-button type="success" size="mini" icon="el-icon-edit" @click="handleUpdate(scope.row)" />-->
              <el-button  type="danger" size="mini" icon="el-icon-delete"  @click="handleDelete(scope.$index, scope.row)"/>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-col>
    <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14" style="padding: 7px;">
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <span class="role-span">營運商API-詳細資訊</span>
          <el-tag v-if="apiVersion!=undefined" style="float: left;" type="danger" size="small">Version {{this.apiVersion}}</el-tag>
<!--          <el-button style="float: right;" type="primary" size="small" @click="handleDetailAdd">新增API</el-button>-->
        </div>
        <el-table
            highlight-current-row
            v-loading="listLoading"
            :data="apiVersionDetailList" style="width: 100%;"
            :cell-style="{padding: '0', height: '40px'}"

        >
          <!--          <el-table-column align="center" prop="id" label="ID" />-->
          <!--          <el-table-column align="center" prop="version" :label="$t('Name')" />-->
          <el-table-column align="center" prop="identifier" label="Identifier" sortable/>
          <el-table-column align="center" prop="role" label="Role" sortable/>
          <el-table-column align="center" prop="url" label="API URL" min-width="120px" :show-overflow-tooltip="true"/>
          <!--          <el-table-column align="center" prop="createTime" label="版本" />-->
          <el-table-column align="center" prop="updateTime" label="更新時間" sortable/>
          <el-table-column align="center" :label="$t('Operate')" width="130px">
            <template slot-scope="scope">
<!--              <el-button type="success" size="mini" icon="el-icon-edit" @click="handleDetailUpdate(scope.row)" />-->
              <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDetailDelete(scope.$index,scope.row)" />
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-col>
    <PartnerApiVersionSetting :dialogVisible="dialogSettingVisible" :apiVersionData="apiVersionData" @close="dialogSettingOnClose()" />
    <PartnerApiDetailSetting :dialogVisible="dialogDetailSettingVisible" :apiDetailData="apiDetailData" @close="dialogDetailSettingOnClose()" />

  </div>
</template>

<script>
import {mixins} from "@/views/common/mixins";
import PartnerApiVersionSetting from "@/views/ocpi/PartnerApiVersionSetting";
import PartnerApiDetailSetting from "@/views/ocpi/PartnerApiDetailSetting";

export default {
  name: "PartnerApiManagement",
  components: {
    PartnerApiVersionSetting,
    PartnerApiDetailSetting
  },
  mixins: [mixins],
  data() {
    return {
      currentRowIndex:0,
      dialogSettingVisible: false,
      dialogDetailSettingVisible: false,
      serviceList: [], //OCPI營運商 (ex: enabled_ocpi=true 之Service)
      apiVersionList: [],
      apiVersionData: {          //API版本資訊
        id: undefined,
        serviceId: undefined,    //營運商id
        apiVersion: '2.2.1',
        url: undefined,
        updateTime: undefined,
      },
      apiVersionDetailList: [],
      apiDetailData: {          //API詳細資訊
        id: undefined,
        serviceId: undefined,    //營運商id
        apiVersion: '2.2.1',
        identifier: 'cdrs',
        role: 'SENDER',
        url: undefined,
        updateTime: undefined,
      },
      listLoading: false,
      listQuery: {
        type: 'general',
        enabled: true,
        name: undefined,
        serviceId: undefined,
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 15
      },
      apiVersion:undefined,
      service: {
        id:undefined,
        code:undefined,
        name:undefined,
        description:undefined,
        enabledOCPI:undefined,
        partnerCPOs:[],
        partnerEMSPs:[],
      },
    };
  },
  watch:{
    'listQuery.serviceId': function () {
      this.apiVersionList = [];
      this.apiVersionDetailList = [];
      this.apiVersionData.serviceId = this.listQuery.serviceId;
      this.apiDetailData.serviceId = this.listQuery.serviceId;
      this.getPartnerVersions(this.listQuery.serviceId);
    },
  },
  methods: {
    setCurrent(row){
      this.$nextTick(()=>{
        this.$refs['apiVersionTable'].setCurrentRow(row);
      });
    },
    //取得OCPI營運商List(營運商下拉式選單)
    getOCPIEnabledServiceOptions() {
      this.listLoading = true
      this.axios.get('/api/v1/ocpi/service').then(res => {
        this.serviceList = res.data
        console.log('getOCPIEnabledServiceOptions',res.data)
        this.listQuery.serviceId = this.serviceList[0].id;
        this.getPartnerVersions(this.listQuery.serviceId);
      })
    },
    //取得營運商Version Info資料
    getPartnerVersions(serviceId) {
      this.listLoading = true
      this.axios.get('/api/v1/ocpi/api/partner/'+serviceId+"/versions", { params: this.listQuery }).then(res => {
        console.log('getPartnerVersionInfo',res)
        this.apiVersionList = res.data.data
        this.listLoading = false
        // if(this.apiVersionList.length>0)
        //   this.getPartnerAPIVersionsDetail(this.apiVersionList[0].apiVersion);
        //呼叫當前rowIndex的版本API詳細資訊
        if(this.apiVersionList.length>0)
        {
          setTimeout(()=>
          {
            console.log("timeout")
            this.$nextTick(()=>{
              this.setCurrent(this.apiVersionList[this.currentRowIndex]);
            })},800);
        }
      })
    },
    getPartnerAPIVersionsDetail(version) {
      this.listLoading = true
      console.log("getPartnerAPIVersionsDetail,version="+version);
      this.axios.get('/api/v1/ocpi/api/partner/'+this.listQuery.serviceId+'/version-detail/'+version).then(res => {
        console.log('getPartnerAPIVersionsDetail',res)
        this.listLoading = false;
        this.apiVersion = version
        this.apiDetailData.apiVersion = version
        this.apiVersionDetailList = res.data.data
      })
    },
    //取得目前el-table點擊row 的index
    tableRowClassName({row, rowIndex}) {
      row.inIndex= rowIndex;
    },
    handleAdd() {
      this.dialogSettingVisible = true;
      this.apiVersionData.serviceId = this.listQuery.serviceId;
    },
    handleDetailAdd(){
      this.dialogDetailSettingVisible = true;
      this.apiDetailData.serviceId = this.listQuery.serviceId;
    },
    dialogSettingOnClose() {
      this.dialogSettingVisible = false
      this.getPartnerVersions(this.listQuery.serviceId)
      this.resetData();
    },
    resetData(){
      this.apiVersionData = {          //API版本資訊
        id :undefined,
        apiVersion : undefined,
        url : undefined,
        updateTime : undefined
      }
    },
    resetDetailData(){
      this.apiDetailData = {          //API詳細資訊
        id: undefined,
        apiVersion: undefined,
        identifier: undefined,
        role: undefined,
        url: undefined,
        updateTime: undefined,
      }
    },
    dialogDetailSettingOnClose(){
      this.dialogDetailSettingVisible = false;
      this.getPartnerAPIVersionsDetail(this.apiVersion);
      this.resetDetailData();
    },
    getSystemAPIVersions() {
      this.listLoading = true
      let vm = this;
      this.axios.get('/api/v1/ocpi/api/system/versions').then(res => {
        //console.log(res)
        this.listLoading = false;
        this.apiVersionList = res.data.data;
        this.getSystemAPIVersionsDetail(this.apiVersionList[0].apiVersion);
      })
      setTimeout(() => {
        console.log("timeout")
        console.log(this.apiVersionList[0])
        console.log();
      },3000)
    },

    handleCurrentChange(val){
      console.log("handleCurrentChange");
      if (val) {
        console.log(val);
        this.currentRowIndex = val.inIndex;
        console.log("this.currentRowIndex="+this.currentRowIndex);
        this.getPartnerAPIVersionsDetail(val.apiVersion);
      }
    },
    handleUpdate(row){
      this.dialogSettingVisible = true;
      this.apiVersionData = row;
      this.apiVersionData.serviceId = this.listQuery.serviceId;
      console.log(this.apiVersionData);
    },
    handleDetailUpdate(row){
      this.dialogDetailSettingVisible = true;
      this.apiDetailData = row;
      this.apiDetailData.serviceId = this.listQuery.serviceId;
    },
    handleDelete(index, row){
      this.apiVersionData.serviceId = this.listQuery.serviceId;
      this.$confirm(this.$t('Hint'), {
        message: '確認刪除版本號:'  + row.apiVersion + ' ?',
        confirmButtonText: this.$t('Confirm'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        this.delService(index, row.id)
      }).catch(()=> {
        console.log('cancel delete service')
      })
    },
    handleDetailDelete(index, row){
      this.apiDetailData.serviceId = this.listQuery.serviceId;
      this.$confirm(this.$t('Hint'), {
        message: '確認刪除API:'  + row.apiVersion +'-'+ row.identifier +'-'+row.role +' ?',
        confirmButtonText: this.$t('Confirm'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        this.delDetailService(index, row.id)
      }).catch(()=> {
        console.log('cancel delete service')
      })
    },
    async delService(index, id) {
      this.axios.delete('/api/v1/ocpi/api/partner/'+this.apiVersionData.serviceId+'/versions/' + id).then(()=> {
        this.$delete(this.apiVersionList, index);
        this.$message({
          showClose: true,
          message: this.$t('Delete') + this.$t('Success'),
          type: 'success'
        })
      }).catch(err => {
        console.log('err:' + err)
        this.$message({
          message: this.$t('Delete') + this.$t('Fail')+","+err.data.msg,
          type: 'error'
        })
      })
    },
    async delDetailService(index, id) {
      this.axios.delete('/api/v1/ocpi/api/partner/'+this.apiDetailData.serviceId+'/version-detail/' + id).then(()=> {
        this.$delete(this.apiVersionDetailList, index);
        this.$message({
          showClose: true,
          message: this.$t('Delete') + this.$t('Success'),
          type: 'success'
        })
      }).catch(err => {
        console.log('err:' + err)
        this.$message({
          message: this.$t('Delete') + this.$t('Fail')+","+err.data.msg,
          type: 'error'
        })
      })
    },
  },
  created() {
    this.getOCPIEnabledServiceOptions();
  }

}
</script>

<style scoped>

</style>
