import { render, staticRenderFns } from "./PartnerApiVersionSetting.vue?vue&type=template&id=b0b83544&scoped=true"
import script from "./PartnerApiVersionSetting.vue?vue&type=script&lang=js"
export * from "./PartnerApiVersionSetting.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0b83544",
  null
  
)

export default component.exports