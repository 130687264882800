<template>
  <div>
    <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10" style="margin-bottom: 10px; padding: 7px;">
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <span class="role-span">系統API-版本資訊</span>
          <el-button style="float: right;" type="primary" size="small" @click="handleAdd">新增版本</el-button>
        </div>
        <el-table
            ref="apiVersionTable"
            highlight-current-row
            v-loading="listLoading"
            :data="apiVersionList"
            style="width: 100%;"
            :cell-style="{padding: '0', height: '40px'}"
            @current-change="handleCurrentChange"
            :row-class-name = "tableRowClassName"
            row-key = "id"
        >
          <el-table-column align="center" prop="apiVersion" label="版本號" sortable/>
          <el-table-column align="center" prop="url" label="API URL" min-width="120px" :show-overflow-tooltip="true"/>
          <el-table-column align="center" prop="updateTime" label="更新時間" min-width="120px" sortable/>
          <el-table-column align="center" :label="$t('Operate')" width="130px">
            <template slot-scope="scope">
              <el-button type="success" size="mini" icon="el-icon-edit" @click="handleUpdate(scope.row)" />
              <el-button  type="danger" size="mini" icon="el-icon-delete"  @click="handleDelete(scope.$index, scope.row)"/>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-col>
    <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14" style="padding: 7px;">
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <span class="role-span">系統API-詳細資訊</span>
          <el-tag style="float: left;" type="danger" size="small">Version {{this.apiVersion}}</el-tag>
          <el-button style="float: right;" type="primary" size="small" @click="handleDetailAdd">新增API</el-button>
        </div>
        <el-table
            highlight-current-row
            v-loading="apiDetailListLoading"
            :data="apiVersionDetailList" style="width: 100%;"
            :cell-style="{padding: '0', height: '40px'}"
        >
          <el-table-column align="center" prop="identifier" label="Identifier" sortable/>
          <el-table-column align="center" prop="role" label="Role" sortable/>
          <el-table-column align="center" prop="url" label="API URL" min-width="120px" :show-overflow-tooltip="true"/>
          <el-table-column align="center" prop="updateTime" label="更新時間" sortable/>
          <el-table-column align="center" :label="$t('Operate')" width="130px">
            <template slot-scope="scope">
              <el-button type="success" size="mini" icon="el-icon-edit" @click="handleDetailUpdate(scope.row)" />
              <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDetailDelete(scope.$index,scope.row)" />
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-col>

    <SystemApiVersionSetting :dialogVisible="dialogSettingVisible" :apiVersionData="apiVersionData" :isModify="isModify" @close="dialogSettingOnClose()" />
    <SystemApiDetailSetting :dialogVisible="dialogDetailSettingVisible" :apiDetailData="apiDetailData" :isModify="isModify" @close="dialogDetailSettingOnClose()" />
  </div>
</template>

<script>
import {mixins} from "@/views/common/mixins";
import SystemApiVersionSetting from "@/views/ocpi/SystemApiVersionSetting";
import SystemApiDetailSetting from "@/views/ocpi/SystemApiDetailSetting";

export default {
  name: "SytemApiManagement",
  components: {
    SystemApiVersionSetting,
    SystemApiDetailSetting
  },
  mixins: [mixins],
  data() {
    return {
      currentRowIndex:0,
      dialogSettingVisible: false,
      dialogDetailSettingVisible: false,
      apiVersionData: {          //API版本資訊
          id: undefined,
          apiVersion: undefined,
          url: undefined,
          updateTime: undefined,
        },
      apiDetailData: {          //API詳細資訊
          id: undefined,
          apiVersion: undefined,
          identifier: undefined,
          role: undefined,
          url: undefined,
          updateTime: undefined,
      },
      isModify: false,
      listLoading: false,
      apiDetailListLoading: false,
      listQuery: {
        type: 'general',
        enabled: true,
        name: undefined,
        serviceId: undefined,
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 15
      },
      apiVersion:"2.2.1",
      apiVersionList: [],
      apiVersionDetailList: [],
      service: {
        id:undefined,
        code:undefined,
        description:undefined,
        enabledOCPI:undefined,
        partnerCPOs:[],
        partnerEMSPs:[],
      },
    };
  },
  methods: {
    setCurrent(row){
      this.$nextTick(()=>{
        this.$refs['apiVersionTable'].setCurrentRow(row);
      });
    },
    //取得目前el-table點擊row 的index
    tableRowClassName({row, rowIndex}) {
      row.inIndex= rowIndex;
    },
    handleAdd() {
      this.dialogSettingVisible = true;
    },
    handleDetailAdd(){
      this.dialogDetailSettingVisible = true;
    },
    dialogSettingOnClose() {
      this.dialogSettingVisible = false
      this.isModify = false;
      this.getSystemAPIVersions();
      this.resetData();
    },
    dialogDetailSettingOnClose(){
      this.isModify = false;
      this.dialogDetailSettingVisible = false;
      this.getSystemAPIVersionsDetail(this.apiVersion);
      this.resetDetailData();
    },
    getSystemAPIVersions() {
      this.listLoading = true
      let vm = this;
      this.axios.get('/api/v1/ocpi/api/system/versions').then(res => {
        this.listLoading = false;
        this.apiVersionList = res.data.data;
        console.log("this.currentRowIndex="+this.currentRowIndex);
        //呼叫當前rowIndex的版本API詳細資訊
        if(this.apiVersionList.length>0)
        {
          setTimeout(()=>
          {
            this.$nextTick(()=>{this.setCurrent(this.apiVersionList[this.currentRowIndex]);
            })},800);
        }

      });
    },
    getSystemAPIVersionsDetail(version) {
      this.apiDetailListLoading = true
      console.log("getSystemAPIVersionsDetail,version="+version);
      this.axios.get('/api/v1/ocpi/api/system/version-detail/'+version).then(res => {
        console.log(res)
        this.apiDetailListLoading = false;
        this.apiVersion = version
        this.apiDetailData.apiVersion = version
        this.apiVersionDetailList = res.data.data
      })
    },
    //點選el-table的row觸發
    handleCurrentChange(val){
      console.log("handleCurrentChange",val);
      if (val) {
        this.currentRowIndex = val.inIndex;
        console.log("this.currentRowIndex="+this.currentRowIndex);
        this.getSystemAPIVersionsDetail(val.apiVersion);
      }
    },
    handleUpdate(row){
      this.dialogSettingVisible = true;
      this.apiVersionData = row;
      this.isModify=true;
    },
    handleDelete(index, row){
      this.$confirm(this.$t('Hint'), {
        message: '確認刪除版本號:'  + row.apiVersion + ' ?',
        confirmButtonText: this.$t('Confirm'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        this.delService(index, row.id)
      }).catch(()=> {
        console.log('cancel delete service')
      })
    },
    handleDetailUpdate(row){
      this.dialogDetailSettingVisible = true;
      this.apiDetailData = row;
      this.isModify = true;
    },
    handleDetailDelete(index, row){
      this.$confirm(this.$t('Hint'), {
        message: '確認刪除API:'  + row.apiVersion +'-'+ row.identifier +'-'+row.role +' ?',
        confirmButtonText: this.$t('Confirm'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        this.delDetailService(index, row.id)
      }).catch(()=> {
        console.log('cancel delete service')
      })
    },
    async delService(index, id) {
      this.axios.delete('/api/v1/ocpi/api/system/versions/' + id).then(()=> {
        this.$delete(this.apiVersionList, index);
        this.$message({
          showClose: true,
          message: this.$t('Delete') + this.$t('Success'),
          type: 'success'
        })
      }).catch(err => {
        console.log('err:' + err)
        this.$message({
          message: this.$t('Delete') + this.$t('Fail')+","+err.data.msg,
          type: 'error'
        })
      })
    },
    async delDetailService(index, id) {
      this.axios.delete('/api/v1/ocpi/api/system/version-detail/' + id).then(()=> {
        this.$delete(this.apiVersionDetailList, index);
        this.$message({
          showClose: true,
          message: this.$t('Delete') + this.$t('Success'),
          type: 'success'
        })
      }).catch(err => {
        console.log('err:' + err)
        this.$message({
          message: this.$t('Delete') + this.$t('Fail')+","+err.data.msg,
          type: 'error'
        })
      })
    },
    resetData(){
      this.apiVersionData = {          //API版本資訊
        id :undefined,
        apiVersion : undefined,
        url : undefined,
        updateTime : undefined
      }
    },
    resetDetailData(){
      this.apiDetailData = {          //API詳細資訊
        id: undefined,
        apiVersion: undefined,
        identifier: undefined,
        role: undefined,
        url: undefined,
        updateTime: undefined,
      }
    },
  },
  created() {
    this.getSystemAPIVersions();
  },
  mounted() {

    },
  updated() {

  },
}
</script>

<style>
.el-table .selected-row {
  background: #ecf5ff;
}

</style>
